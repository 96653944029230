import { Text } from "@chakra-ui/react";
import ErrorPage from "components/Shared/ErrorPage";
import ContactEmailLink from "components/Shared/ContactEmailLink";
import HomeButton from "components/Shared/HomeButton";

function NotFoundPage() {
  return (
    <ErrorPage heading="Page not found">
      <Text mt={4} fontSize="xl">
        The requested URL cannot be found. This could be a typo or the page may
        have been removed. If you believe this is an error, please contact{" "}
        <ContactEmailLink />.
      </Text>
      <HomeButton />
    </ErrorPage>
  );
}

export default NotFoundPage;

NotFoundPage.allowUnauthenticated = true;
